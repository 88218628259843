import React, { FC } from "react";
import * as styles from "./Pillars.module.scss";
import PillarItem from "./PillarItem";
import {
  pillarsData,
  PillarsType,
} from "../OptimizationHelpers/OptimizationHelper";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";
import translate from "../../../i18n/translate";

const Pillars: FC = () => {
  return (
    <div className={styles.pillars}>
      <CircleBlur
        initialScale={0.1}
        initialOpacity={0}
        animateScale={1.8}
        animateOpacity={0.2}
        transitionType={"easeIn"}
        transitionDelay={0.2}
        transitionDuration={1}
        circleStyleClass={styles.heroBackgroundInnerCircleLeft1}
      />
      <CircleBlur
        initialScale={0.1}
        initialOpacity={0}
        animateScale={1.8}
        animateOpacity={0.2}
        transitionType={"easeIn"}
        transitionDelay={0.2}
        transitionDuration={1}
        circleStyleClass={styles.heroBackgroundInnerCircleLeft2}
      />
      <h2 className={styles.head}>
        <span>.</span>
        {translate("optimization-pillars")}
      </h2>

      {pillarsData.map((pillar: PillarsType, index: number) => (
        <PillarItem
          pillarsNumber={pillar.pillarsNumber}
          pillarsDescription={pillar.pillarsDescription}
          pillarsTitle={pillar.pillarsTitle}
          pillarsImage={pillar.pillarsImage}
          key={index}
          index={index}
          pillarsData={pillarsData}
          pillarsImageTag={pillar.alt}
        />
      ))}
      <CircleBlur
        initialScale={0.1}
        initialOpacity={0}
        animateScale={1.8}
        animateOpacity={0.2}
        transitionType={"easeIn"}
        transitionDelay={0.2}
        transitionDuration={1}
        circleStyleClass={styles.heroBackgroundInnerCircleLeft}
      />
      <CircleBlur
        initialScale={0.1}
        initialOpacity={0}
        animateScale={1.8}
        animateOpacity={0.2}
        transitionType={"easeIn"}
        transitionDelay={0.2}
        transitionDuration={1}
        circleStyleClass={styles.heroBackgroundInnerCircleRight}
      />

      <CircleBlur
        initialScale={0.1}
        initialOpacity={0}
        animateScale={1.8}
        animateOpacity={0.2}
        transitionType={"easeIn"}
        transitionDelay={0.2}
        transitionDuration={1}
        circleStyleClass={styles.heroBackgroundInnerCircleCenter}
      />
    </div>
  );
};

export default Pillars;
