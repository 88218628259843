import React, { FC } from "react";
import { ModalProps } from "../Shared/MobileModal/MobileModal";
import { UseWindowSize } from "../hooks/UseWindowSize";
import stageImage from "../../images/optimizationHeader.webp";
import stageImageMobile from "../../images/mobile-images/optimizationHeaderMobile.webp";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import ContactForm from "../../components/Shared/ContactUiComponents/ContactForm";
import Pillars from "./Pillars/Pillars";
import SectionWrapper from "../Shared/SectionWrapper/SectionWrapper";
import {
  ourApproachData,
  BenefitData,
  TwoColumnBannerData,
} from "./OptimizationMain.helper";
import CloudInsightSliderComponent from "../Shared/CloudInsightSlider/CloudInsightSliderComponent";
import OurApproachComponent from "../Shared/OurApproachComponent/OurApproachComponent";
import CaseStudiesSliderComponent from "../Shared/CaseStudiesSlider/CaseStudiesSliderComponent";
import BenefitsComponent from "../Shared/BenefitsComponent/BenefitsComponent";
import TwoColumnBanner from "../Shared/TwoColumnImgTextbanner/TwoColumnBanner";
import HeroBanner from "../Shared/HeroBanner/HeroBanner";
import HeroBannerMobile from "../Shared/HeroBannerMobile/HeroBannerMobile";
import * as styles from "../CareersUiComponents/CareersMain.module.scss";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";
import translate from "../../i18n/translate";

type Props = {
  data: any;
};

const OptimizationMain: FC<ModalProps & Props> = ({
  isModalHidden,
  setIsModalHidden,
  modalContent,
  data,
}) => {
  const width = UseWindowSize();

  return (
    <div>
      <CircleBlur
        initialScale={0.1}
        initialOpacity={0}
        animateScale={1.8}
        animateOpacity={0.25}
        transitionType={"easeIn"}
        transitionDelay={0.2}
        transitionDuration={1}
        circleStyleClass={styles.heroBackgroundInnerCircleLeft}
      />
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={translate("optimization-intro")}
          description={translate("optimization-description")}
          homeBackground={stageImageMobile}
          buttonText={translate("home-contactus")}
          date=""
          link="/contact"
          alt="stage"
        />
      ) : (
        <HeroBanner
          heading={translate("optimization-intro")}
          description={translate("optimization-description")}
          homeBackground={stageImage}
          buttonText={translate("home-contactus")}
          date=""
          link="/contact"
        />
      )}
      <InnerWrapper>
        <TwoColumnBanner data={TwoColumnBannerData} headingTop={true} />
        <SectionWrapper>
          <div className={styles.pillars}>
            <Pillars />
          </div>
        </SectionWrapper>

        <SectionWrapper>
          <BenefitsComponent data={BenefitData} />
        </SectionWrapper>
        <SectionWrapper>
          <CaseStudiesSliderComponent width={width.width} data={data} />
        </SectionWrapper>
        <SectionWrapper>
          <div className={styles.approach}>
            <OurApproachComponent
              ourApproachData={ourApproachData}
              heading={translate("optimization-ourApproach")}
            />
          </div>
        </SectionWrapper>
        <SectionWrapper>
          <CloudInsightSliderComponent />
        </SectionWrapper>
        <SectionWrapper>
          <div className={styles.contact}>
            <ContactForm heading={translate("contact-optimizeHeading")} />
          </div>
        </SectionWrapper>
      </InnerWrapper>
    </div>
  );
};

export default OptimizationMain;
