// extracted by mini-css-extract-plugin
export var head = "Pillars-module--head--oot4n";
export var heroBackgroundInnerCircleCenter = "Pillars-module--heroBackgroundInnerCircleCenter--z2PGt";
export var heroBackgroundInnerCircleLeft = "Pillars-module--heroBackgroundInnerCircleLeft--ViCbn";
export var heroBackgroundInnerCircleLeft1 = "Pillars-module--heroBackgroundInnerCircleLeft1--Rg+nI";
export var heroBackgroundInnerCircleLeft2 = "Pillars-module--heroBackgroundInnerCircleLeft2--F7cey";
export var heroBackgroundInnerCircleRight = "Pillars-module--heroBackgroundInnerCircleRight--EG43C";
export var pillars = "Pillars-module--pillars--pFeOE";
export var pillarsDescription = "Pillars-module--pillarsDescription--qetR+";
export var pillarsDescriptionTitle = "Pillars-module--pillarsDescriptionTitle--Tp4p+";
export var pillarsImage = "Pillars-module--pillarsImage--Af+al";
export var pillarsItem = "Pillars-module--pillarsItem--gFjRq";
export var pillarsNumber = "Pillars-module--pillarsNumber--w-BXW";