import { graphql } from "gatsby";
import React, { FC } from "react";
import Layout from "../components/Layout/Layout";
import OptimizationMain from "../components/OptimizationUiComponents/OptimizationMain";
import { SEO } from "../components/seo";
import headerimg from "../images/optimizationHeader.png";

type Props = {
  data: any;
};

const Optimization: FC<Props> = ({ data }) => {
  return (
    <Layout location="/blog">
      <SEO
        title="Cloud Optimization"
        description="Cloud computing requires a new way of thinking about architecture and design. We evaluate your cloud environment and re-architect for max efficiency and security."
        type="website"
        image={headerimg}
      />
      <OptimizationMain data={data} />
    </Layout>
  );
};

export const query = graphql`
  query allOptimizeBlogs {
    caseStudies: allSanityCaseStudy(
      filter: { tags: { elemMatch: { name: { eq: "Optimization" } } } }
      sort: { order: DESC, fields: _createdAt }
      limit: 5
    ) {
      edges {
        node {
          id
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          about {
            _type
            children {
              _key
              _type
              text
            }
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
          }
          altMainImage
          caseStudyDescription {
            children {
              text
            }
          }
          title
        }
      }
    }
    cloudInsights: allSanityBlog(
      filter: { blogType: { elemMatch: { title: { eq: "cloud-insights" } } } }
      sort: { order: DESC, fields: publishedAt }
      limit: 5
    ) {
      edges {
        node {
          id
          title
          subtitle
          publishedAt
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX)
            }
          }
          alt
        }
      }
    }
  }
`;

export default Optimization;
