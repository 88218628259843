import operationalExcellence from "../../../images/operationalExcellence.png";
import securityPillars from "../../../images/securityPillars.png";
import reliabilityPillars from "../../../images/reliabilityPillars.png";
import costOptimizationPillars from "../../../images/costOptimizationPillars.png";
import eficiencyPillars from "../../../images/eficiencyPillars.png";
import translate from "../../../i18n/translate";

export type PillarsType = {
  pillarsNumber: string;
  pillarsTitle: string;
  pillarsImage: any;
  pillarsDescription: string;
  alt: string;
};

export const pillarsData: PillarsType[] = [
  {
    pillarsNumber: "01",
    pillarsTitle: translate("optimization-pillarsTitle1"),
    pillarsImage: operationalExcellence,
    pillarsDescription: translate("optimization-pillarsDescription1"),
    alt: "Structural shot of desert setting from above",
  },
  {
    pillarsNumber: "02",
    pillarsTitle: translate("optimization-pillarsTitle2"),
    pillarsImage: securityPillars,
    pillarsDescription: translate("optimization-pillarsDescription2"),
    alt: "Structural shot of icy environment from above",
  },
  {
    pillarsNumber: "03",
    pillarsTitle: translate("optimization-pillarsTitle3"),
    pillarsImage: reliabilityPillars,
    pillarsDescription: translate("optimization-pillarsDescription3"),
    alt: "Structural shot of several layers of green hills from above",
  },
  {
    pillarsNumber: "04",
    pillarsTitle: translate("optimization-pillarsTitle4"),
    pillarsImage: costOptimizationPillars,
    pillarsDescription: translate("optimization-pillarsDescription4"),
    alt: "Structural shot of white light gray rock from above",
  },
  {
    pillarsNumber: "05",
    pillarsTitle: translate("optimization-pillarsTitle5"),
    pillarsImage: eficiencyPillars,
    pillarsDescription: translate("optimization-pillarsDescription5"),
    alt: "Structural shot of white mountains from above",
  },
];
