import timeFrame from "../../images/timeFrame.svg";
import founded from "../../images/founded.svg";
import planed from "../../images/planed.svg";
import gdnPromote from "../../images/optimizationImprovement.webp";
import risk from "../../images/risk.svg";
import learn from "../../images/learn.svg";
import translate from "../../i18n/translate";

export const ContactFormData = {
  heading: "Optimization by cloud-native experts",
};
export const ourApproachData = [
  {
    count: "01",
    heading: translate("optimization-ourApproachHeading1"),
    description: translate("optimization-ourApproachDescription1"),
  },
  {
    count: "02",
    heading: translate("optimization-ourApproachHeading2"),
    description: translate("optimization-ourApproachDescription2"),
  },
  {
    count: "03",
    heading: translate("optimization-ourApproachHeading3"),
    description: translate("optimization-ourApproachDescription3"),
  },
];
export const BenefitData = {
  heading: ".Benefits",
  benefitsDataMobile: [
    {
      count: "01",
      img: timeFrame,
      heading: translate("optimization-benefitsHeading1"),
      description: translate("optimization-benefitsDescription1"),
      alt: "globaldatanet time is money icon",
    },
    {
      count: "02",
      img: risk,
      heading: translate("optimization-benefitsHeading2"),
      description: translate("optimization-benefitsDescription2"),
      alt: "globaldatanet risk reduction icon",
    },
    {
      count: "03",
      img: founded,
      heading: translate("optimization-benefitsHeading3"),
      description: translate("optimization-benefitsDescription3"),
      alt: "globaldatanet whiteboard goal icon",
    },
    {
      count: "04",
      img: learn,
      heading: translate("optimization-benefitsHeading4"),
      description: translate("optimization-benefitsDescription4"),
      alt: "globaldatanet learning screen icon",
    },
    {
      count: "05",
      img: planed,
      heading: translate("optimization-benefitsHeading5"),
      description: translate("optimization-benefitsDescription5"),
      alt: "globaldatanet ideas for improvement icon",
    },
  ],
};

export const TwoColumnBannerData = {
  heading: translate("optimization-improvement"),
  details: translate("optimization-improvementDescription"),
  img: gdnPromote,
  alt: "Construction site from above to represent continuous improvement",
};
