import React, { FC } from "react";
import * as styles from "./Pillars.module.scss";
import pillarsLine from "../../../images/pillarsLine.svg";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import { PillarsType } from "../OptimizationHelpers/OptimizationHelper";

type Props = {
  pillarsNumber: string;
  pillarsTitle: string;
  pillarsDescription: string;
  index: number;
  pillarsData: PillarsType[];
  pillarsImage: any;
  pillarsImageTag: string;
};

const PillarItem: FC<Props> = ({
  pillarsNumber,
  pillarsTitle,
  pillarsDescription,
  index,
  pillarsData,
  pillarsImage,
  pillarsImageTag,
}) => {
  const { width } = UseWindowSize();

  return (
    <div className={styles.pillarsItem}>
      <div
        className={styles.pillarsNumber}
        style={{ order: width <= 770 ? 2 : index % 2 === 0 ? 1 : 2 }}
      >
        <span>{pillarsNumber}</span>
        {pillarsData.length - 1 !== index ? (
          <img src={pillarsLine} alt="pillarsLine" />
        ) : null}
      </div>
      <div
        className={styles.pillarsDescription}
        style={{ order: width <= 770 ? 3 : index % 2 === 0 ? 2 : 3 }}
      >
        <span className={styles.pillarsDescriptionTitle}>{pillarsTitle}</span>
        <p>{pillarsDescription}</p>
      </div>
      <div
        className={styles.pillarsImage}
        style={{ order: width <= 770 ? 1 : index % 2 === 0 ? 3 : 1 }}
      >
        <img src={pillarsImage} alt={pillarsImageTag} />
      </div>
    </div>
  );
};

export default PillarItem;
